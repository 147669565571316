<template>
    <div
        class="rating"
        :class="location"
    >
        <div class="stars">
            <div class="rating-stars" />
            <div
                class="rating-inner"
                :style="{ width: ratingPercentage + '%' }"
            />
        </div>
        <div
            v-if="location == 'sidebar' || location == 'product'"
            class="rating-text"
        >
            <span>{{ rating.value }}</span><span>({{ rating.counter }})</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RatingStars',
        props: {
            rating: {
                type: Object,
                required: true,
            },
            location: {
                type: String,
                default: '',
            },
        },
        computed: {
            ratingPercentage() {
                return (this.rating.value * 100) / 5;
            },
        },
    };
</script>

<style scoped lang="scss">
.rating {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
  height: 18px;
  &.single-product {
    margin: 0;
  }
  &:not(.sidebar) {
    @include media-breakpoint-down(xm) {
      gap: 0px;
    }
  }
  &.sidebar,
  &.content {
    margin-bottom: 0;
    .rating-text {
      span {
        font-weight: 400;
      }
    }
  }
  &.product {
    @include media-breakpoint-down(md) {
      gap: 8px;
      .rating-text {
        display: flex;
        justify-content: center;
        p {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
    .rating-text {
      span {
        display: block;
        &:first-of-type {
          display: none;
        }
      }
    }
  }

  .stars {
    position: relative;
    width: 100px;
    height: 18px;
    @include media-breakpoint-down(lg) {
      height: 16px;
      width: 80px;
    }
    .rating-stars {
      position: absolute;
      left: -2px;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url('/images/star-grey.svg');
      background-repeat: repeat-x;
      background-size: 20px;
      @include media-breakpoint-down(lg) {
        background-size: 16px;
      }
    }
    .rating-inner {
      height: 100%;
      background-image: url('/images/star-yellow.svg');
      background-repeat: repeat-x;
      background-size: 20px;
      position: absolute;
      top: 0;
      left: -2px;
      @include media-breakpoint-down(lg) {
        background-size: 16px;
      }
    }
  }
  .rating-text {
    height: 18px;

    span {
      color: $gray;
      font-weight: 600;
      font-size: 14px;
    }
    @include media-breakpoint-down(lg) {
      height: 16px;
      span {
        line-height: normal;
        font-size: 12px;
      }
    }
  }
}
</style>
