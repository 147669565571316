<template>
    <div
        v-if="loaded"
        class="listing-actions mb-4 d-flex sb"
    >
        <div class="d-flex left-column">
            <p class="sm-sbold mr-8">
                {{ totalText }}
            </p>
            <div
                v-if="!noProducts"
                class="separator"
            />
            <listing-per-page
                v-if="!noProducts"
                :is-search="isSearch"
                :per-page="perPage"
            />
        </div>
        <listing-sorter
            v-if="!noProducts"
            :is-search="isSearch"
            :sort="page.sort"
            :discount-exist="discountExist"
            :sorter-bar-active="sorterBarActive"
            :sorter-active="sorterActive"
            @toggleSorter="toggleSorter"
        />
    </div>
</template>

<script>
    import ListingPerPage from '@/pages/category/ListingPerPage';
    import ListingSorter from '@/pages/category/ListingSorter';
    export default {
        name: 'ListingActions',
        components: { ListingSorter, ListingPerPage },
        props: {
            page: {
                type: Object,
                required: true,
            },
            discountExist: {
                type: Boolean,
                default: false,
            },
            isSearch: {
                type: Boolean,
                default: false,
            },
            sorterBarActive: {
                type: Boolean,
                default: false,
            },
            sorterActive: {
                type: Boolean,
                default: false,
            },
            notExistLabel: {
                type: String,
                required: true
            }
        },
        computed: {
            totalText() {
                if (this.page.total != 0) {
                    return this.$t('page.heading.total', { number: this.page.total });
                }
                return this.notExistLabel;
            },
            noProducts() {
                return this.page.total == 0;
            },
            loaded() {
                return Object.keys(this.page).length > 0;
            },
            perPage(){
                return parseInt(this.page.perPage);
            }
        },
        methods: {
            toggleSorter() {
                this.$emit('toggleSorter');
            },
        },
    };
</script>
<style scoped lang="scss">
.listing-actions {
  .left-column {
    align-items: center;
    p {
      color: $text;
    }
    .separator {
      width: 1px;
      height: 16px;
      background-color: $gray-light;
    }
  }
}
</style>
