<template>
    <span
        key="perPage"
        v-click-outside="closeDropdown"
        class="dropdown perPage"
        :class="{ active: perPageIsActive }"
        @click="perPageIsActive = !perPageIsActive"
    >
        <p class="sm-sbold">{{ $t('page.heading.perPageLabel') }}</p>
        <p class="sm-sbold gd">{{ query.value.perPage }} {{ $t('page.heading.perPageText') }}</p>
        <chevron-down-icon />
        <ul>
            <li
                v-for="pageNumber in pageNumbers"
                :key="`page-${pageNumber}`"
                :class="{ active: isActive(pageNumber) }"
                @click="change(pageNumber)"
            >
                <p class="sm-sbold">
                    {{ pageNumber }} {{ $t('page.heading.perPageText') }}
                </p>
            </li>
        </ul>
    </span>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import ChevronDownIcon from 'vue-feather-icons/icons/ChevronDownIcon';
    import { mapActions } from 'vuex';

    export default {
        name: 'ListingPerPage',
        components: {
            ChevronDownIcon,
        },
        props: {
            perPage: {
                type: Number,
                default: 24,
            },
            isSearch: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                perPageIsActive: false,
                query: {
                    type: 'perPage',
                    value: {
                        perPage: 24,
                    },
                },
                pageNumbers: [24, 48, 72],
            };
        },
        methods: {
            ...mapActions('category', ['getProducts']),
            closeDropdown() {
                this.perPageIsActive = false;
            },
            change(perPage) {
                if (this.query.value.perPage != perPage) {
                    this.query.value.perPage = perPage;
                    this.getProducts({ query: this.query, isSearch: this.isSearch });
                }
            },
            isActive(perPage) {
                return perPage == this.query.value.perPage;
            },
            updatePerPage() {
                this.query.value.perPage = this.perPage;
            },
        },
        directives: {
            ClickOutside,
        },
        mounted() {
            this.updatePerPage();
        },
        watch: {
            perPage() {
                this.updatePerPage();
            },
        },
    };
</script>
<style scoped lang="scss">
.left-column {
  display: flex;
  align-items: center;
  > p {
    color: $text;
    display: flex;
    align-items: center;
    padding-right: 16px;
    height: 21px;
    border-right: 1px solid $gray-light;
  }
}
</style>
