<template>
    <div
        v-click-outside="closeFilter"
        class="side-box"
        :class="{
            closed: !filterIsActive,
            'quick-filter': location == 'content',
            mobile: isMobileFilter,
        }"
    >
        <div class="filter-container">
            <div
                v-if="location == 'content'"
                class="filter-label"
            >
                <p class="sm">
                    {{ label }}
                </p>
            </div>
            <div
                class="filter-header"
                :class="{ disabled: disabled }"
            >
                <div
                    v-if="location == 'content'"
                    class="close-filter"
                    @click="resetFilter()"
                >
                    <x-icon />
                </div>
                <span
                    v-if="!isMobileFilter"
                    class="d-flex"
                    @click="filterIsActive = !filterIsActive"
                >
                    <p :class="location == 'sidebar' ? 'sbold' : 'sm-sbold'">
                        {{ activeFilterValue }}
                    </p>
                    <chevron-up-icon />
                </span>
                <span v-else>
                    <p class="sidebar sm-sbold">{{ activeFilterValue }}</p>
                </span>
            </div>
        </div>

        <div
            v-if="!isMobileFilter"
            class="filter-content"
        >
            <div class="inner-filter">
                <input
                    v-if="filters.length > 6"
                    v-model="filterSearch"
                    type="text"
                    name="filterSearch"
                    :placeholder="$t('page.category.filters.searchPlaceholder')"
                >

                <ul
                    v-if="filteredData.length > 0"
                    class="scrollable"
                >
                    <li
                        v-for="filter in filteredData"
                        :key="`filter-${filteredData.indexOf(filter)}`"
                    >
                        <label>
                            <div class="text">{{ filter.name }}</div>
                            <div
                                v-if="location == 'sidebar'"
                                class="quantity"
                            >
                                ({{ filter.count }})
                            </div>
                            <div class="custom-checkbox">
                                <input
                                    :id="location + '-' + attributeCategory + '-' + filterKey + '-' + filter.key"
                                    :key="location + '-' + filterKey + '-' + filter.key"
                                    v-model="values"
                                    type="checkbox"
                                    :name="filter.key"
                                    :value="filter.key"
                                    @change="updateFilter()"
                                >
                                <div class="checkmark">
                                    <check-icon />
                                </div>
                            </div>
                        </label>
                    </li>
                </ul>
                <p
                    v-else
                    class="sm-sbold noResult"
                >
                    {{ $t('page.category.filters.searchNoResult') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import ChevronUpIcon from 'vue-feather-icons/icons/ChevronUpIcon';
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { mapActions, mapState } from 'vuex';
    export default {
        name: 'StandardFilter',
        components: {
            CheckIcon,
            ChevronUpIcon,
            XIcon,
        },
        props: {
            location: {
                type: String,
                default: 'sidebar',
            },
            filters: {
                type: Array,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            filterKey: {
                type: String,
                required: true,
            },
            attributeCategory: {
                type: String,
                default: '',
            },
            activeFilter: {
                type: Array,
                default() {
                    return [];
                },
            },
            isSearch: {
                type: Boolean,
                default: false,
            },
            isMobileFilter: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                filterSearch: '',
                filterIsActive: true,
                values: [],
                query: {
                    type: 'filter',
                    key: '',
                    value: [],
                },
            };
        },
        computed: {
            ...mapState('category', [
                'mobileFilterAccordions',
                'products',
                'filtersCount',
            ]),
            activeFilterValue() {
                let labels = [];
                this.query.value.forEach((attributeKey) => {
                    this.filters.forEach((element) => {
                        if (element.key == attributeKey) {
                            labels.push(element.name);
                        }
                    });
                });

                return this.location == 'content' ? labels.join(',') : this.label;
            },
            disabled() {
                return this.products.elements.length == 0 && this.filtersCount > 0;
            },
            filteredData() {
                const searchTerm = this.filterSearch.toLowerCase();
                return this.filters.filter((item) =>
                    item.name.toLowerCase().includes(searchTerm)
                );
            },
        },
        methods: {
            ...mapActions('category', ['getProducts']),
            resetFilter() {
                let resetQuery = { type: 'filter', key: this.filterKey, value: [] };
                if (this.filterKey == 'attributes') {
                    resetQuery.value['key'] = this.attributeCategory;
                    resetQuery.value['value'] = [];
                } else {
                    resetQuery.value = [];
                }

                this.values = [];
                this.getProducts({ query: resetQuery, isSearch: this.isSearch });
            },
            closeFilter() {
                if (this.location === 'content') {
                    this.filterIsActive = false;
                }
            },
            updateFilter() {
                this.query.key = this.filterKey;
                if (this.filterKey == 'attributes') {
                    this.query.value['key'] = this.attributeCategory;
                    this.query.value['value'] = this.values;
                } else {
                    this.query.value = this.values;
                }
                this.getProducts({ query: this.query, isSearch: this.isSearch });
            },
            initiateFilter() {
                this.values = this.activeFilter;
                this.query.value = this.activeFilter;
            },
        },
        mounted() {
            if (this.location === 'content') {
                this.filterIsActive = false;
            }
            this.initiateFilter();
        },
        watch: {
            activeFilter() {
                this.values = this.activeFilter;
                this.initiateFilter();
            },
            mobileFilterAccordions: {
                handler(value) {
                    if (!value) {
                        this.filterIsActive = true;
                    }
                },
            },
        },
        directives: {
            ClickOutside,
        },
    };
</script>
