<template>
    <div class="side-box">
        <div class="parent-category">
            <img
                v-if="categories.icon"
                :src="categories.icon"
                :alt="categoryLabel"
            >
            <p class="sbold">
                {{ categoryLabel }}
            </p>
        </div>
        <div class="filter-content">
            <div class="inner-filter">
                <ul class="scrollable">
                    <li
                        v-for="category in categories.elements"
                        :key="`product-${categories.elements.indexOf(category)}`"
                        :class="{
                            'has-subcategories':
                                category.elements && category.elements.length > 0,
                            active:
                                category.name === current.name ||
                                category.slug === category_slug,
                        }"
                    >
                        <a
                            :class="{ 'search-category': isSearch }"
                            class="search-category"
                            @click="updateFilter(category)"
                        >
                            <div class="text">
                                {{ category.name }}
                            </div>
                            <div class="quantity">({{ category.count }})</div>
                        </a>
                        <ul>
                            <li
                                v-for="subcategory in category.elements"
                                :key="`product-${category.elements.indexOf(subcategory)}`"
                            >
                                <router-link :to="{ path: '/categorie/' + subcategory.path }">
                                    <div class="text">
                                        {{ subcategory.name }}
                                    </div>
                                    <div class="quantity">
                                        ({{ subcategory.count }})
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapState } from 'vuex';
    export default {
        name: 'CategoryFilter',
        props: {
            current: {
                type: [Object, String],
                required: true,
            },
            categories: {
                type: Object,
                required: true,
            },
            isSearch: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                query: {
                    type: 'category',
                    key: 'categorie',
                    value: '',
                },
            };
        },
        computed: {
            ...mapState('category', ['category_slug']),
            categoryLabel() {
                return !this.isSearch
                    ? this.categories.name
                    : this.$t('page.search.categoryBoxText');
            },
        },
        methods: {
            ...mapActions('category', ['getProducts']),
            updateFilter(category) {
                if(this.isSearch){
                    this.query.value = category.slug;
                    this.getProducts({
                        query: this.query,
                        isSearch: this.isSearch,
                    });
                }else{
                    let slugs = category.path.split('/');
                    let route = {
                        name: 'Categorie',
                        params: {}
                    };

                    if (slugs[0]) route.params.slug1 = slugs[0];
                    if (slugs[1]) route.params.slug2 = slugs[1];
                    if (slugs[2]) route.params.slug3 = slugs[2];

                    // Perform the navigation
                    this.$router.push(route).catch(() => {
                        //
                    });

                }
            },
        },
    };
</script>
<style scoped lang="scss">
.side-box {
  .parent-category {
    display: flex;
    padding: 0px 8px;
    @include media-breakpoint-down(md) {
      padding: 0px 16px;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
    p {
      flex: 1;
      &.sbold {
        cursor: auto;
      }
    }
    p {
      margin: 0 4px;
      @include media-breakpoint-down(md) {
        margin: 0;
      }
    }
  }
  .search-category {
    cursor: pointer;
  }
}
</style>
