<template>
    <div
        class="mobile-filters col"
        :class="{ sticky: scrolled, active: active }"
    >
        <b-button
            variant="primary"
            class="stroke sm"
            @click="toggleFilter()"
        >
            <span class="filter-label">
                {{ $t('page.category.filters.heading') }}</span>
            <sliders-icon
                size="1.5x"
                class="custom-class"
            />
            <span
                v-if="filtersCount > 0"
                class="count"
            >{{ filtersCount }}</span>
        </b-button>
        <b-button
            variant="primary"
            class="stroke sm icon"
            @click="toggleSorter()"
        >
            <img
                src="/images/swap-vertical-blue.svg"
                width="16px"
                height="16px"
                alt="sort"
            >
        </b-button>
    </div>
</template>

<script>
    import SlidersIcon from 'vue-feather-icons/icons/SlidersIcon';
    import { mapMutations, mapState } from 'vuex';

    export default {
        name: 'ListingFilterActions',
        components: {
            SlidersIcon,
        },
        data() {
            return {
                filtersActive: false,
                sorterActive: false,
                scrolled: false,
                active: false,
            };
        },
        computed: {
            ...mapState('category', ['filtersCount', 'mobileFiltersActive']),
        },
        methods: {
            ...mapMutations('category', ['setMobileFiltersStatus', 'toggleAccordions']),
            toggleFilter() {
                if (!this.mobileFiltersActive) {
                    this.setMobileFiltersStatus(true);
                    document.body.classList.add('no-scrolling');
                    this.toggleAccordions(true);
                } else {
                    setTimeout(() => {
                        this.setMobileFiltersStatus(false);
                        document.body.classList.remove('no-scrolling');
                    }, 301);
                }
            },
            toggleSorter() {
                this.$emit('toggleSorter');
            },
            handleScroll() {
                if (window.scrollY > 150 && !this.scrolled) {
                    this.scrolled = true;
                    setTimeout(() => {
                        this.active = true;
                    }, 100);
                } else if (window.scrollY <= 150 && this.scrolled) {
                    this.active = false;
                    setTimeout(() => {
                        this.scrolled = false;
                    }, 100);
                }
            },
        },
        created() {
            if (this.$screen.breakpoint == 'sm' || this.$screen.breakpoint == 'md') {
                window.addEventListener('scroll', this.handleScroll);
            }
        },
        destroyed() {
            if (this.$screen.breakpoint == 'sm' || this.$screen.breakpoint == 'md') {
                window.removeEventListener('scroll', this.handleScroll);
            }
        },
    };
</script>
