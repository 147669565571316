<template>
    <div
        class="listing-sorter"
        :class="classes"
    >
        <div
            class="listing-inner"
            :class="{ active: sorterActive }"
        >
            <div class="sorter-popup">
                <p class="sbold">
                    {{ $t('page.heading.sort.label') }}
                </p>
                <b-button
                    variant="link"
                    class="close icon"
                    @click="closeSorter()"
                >
                    <x-icon />
                </b-button>
            </div>
            <span
                v-click-outside="close"
                class="dropdown"
                :class="{ active: sorterIsActive }"
                @click="sorterIsActive = !sorterIsActive"
            >
                <p
                    v-if="!isMobile"
                    class="sm-sbold"
                >
                    {{ $t('page.heading.sort.label') }}
                </p>
                <img
                    v-else
                    class="sort-icon"
                    src="/images/swap-vertical.svg"
                    :alt="label"
                    width="16px"
                    height="16px"
                >
                <p class="sm-sbold">{{ label }}</p>
                <chevron-down-icon />
                <ul>
                    <li
                        v-for="(sort, key) in sortingItems"
                        :key="`sort-${key}`"
                        :class="{ active: isActive(sort.key) }"
                        @click="sortBy(sort.key)"
                    >
                        <p class="sm-sbold">{{ sort.value }}</p>
                    </li>
                </ul>
            </span>
        </div>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import ChevronDownIcon from 'vue-feather-icons/icons/ChevronDownIcon';
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { mapActions } from 'vuex';

    import { kebabToCamel } from '@/services/strUtils';

    export default {
        name: 'ListingSorter',
        components: {
            ChevronDownIcon,
            XIcon,
        },
        props: {
            sort: {
                type: String,
                default: 'sales-counter',
            },
            discountExist: {
                type: Boolean,
                default: false,
            },
            isSearch: {
                type: Boolean,
                default: false,
            },
            sorterActive: {
                type: Boolean,
                default: false,
            },
            sorterBarActive: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                sorterIsActive: false,
                query: {
                    type: 'sorting',
                    value: 'sales-counter',
                },
                sorting: {
                    rating: { key: 'rating', value: this.$t('page.heading.sort.rating') },
                    priceAsc: {
                        key: 'price-asc',
                        value: this.$t('page.heading.sort.priceAsc'),
                    },
                    priceDesc: {
                        key: 'price-desc',
                        value: this.$t('page.heading.sort.priceDesc'),
                    },
                    salesCounter: {
                        key: 'sales-counter',
                        value: this.$t('page.heading.sort.salesCounter'),
                    },
                    new: { key: 'new', value: this.$t('page.heading.sort.new') },
                    discount: {
                        key: 'discount',
                        value: this.$t('page.heading.sort.discount'),
                    },
                },
            };
        },
        computed: {
            label() {
                return this.sorting[kebabToCamel(this.query.value)].value;
            },
            sortingItems() {
                let sortingValues = Object.assign({}, this.sorting);
                if (!this.discountExist) {
                    delete sortingValues.discount;
                }
                return sortingValues;
            },
            classes() {
                let classes = '';
                classes += this.sorterBarActive ? 'active ' : '';
                classes += this.isMobile ? 'responsive' : '';
                return classes;
            },
            isMobile() {
                return this.$screen.breakpoint == 'md';
            },
        },
        methods: {
            ...mapActions('category', ['getProducts']),
            close() {
                this.sorterIsActive = false;
            },
            sortBy(sortKey) {
                if (this.query.value != sortKey) {
                    this.query.value = sortKey;
                    this.getProducts({ query: this.query, isSearch: this.isSearch });
                }
            },
            isActive(sortKey) {
                return sortKey == this.query.value;
            },
            updateSorting() {
                this.query.value = this.sort;
            },
            closeSorter() {
                this.$emit('toggleSorter');
            },
        },
        directives: {
            ClickOutside,
        },
        mounted() {
            this.updateSorting();
        },
        watch: {
            sort() {
                this.updateSorting();
                if (this.$screen.breakpoint == 'md' && this.sorterIsActive) {
                    this.closeSorter();
                }
            },
        },
    };
</script>
<style scoped lang="scss">
.listing-sorter {
  text-align: right;
  @include media-breakpoint-up(lg) {
    min-width: 250px;
  }
  .sorter-popup {
    display: none;
    justify-content: space-between;
    padding: 4px 16px;
    align-items: center;
    border-bottom: 1px solid $gray-light;
    .btn {
      margin: 0;
      svg {
        height: 16px;
        width: 16px;
        stroke: $gray;
      }
    }
  }
  .dropdown {
    display: inline-flex;
    .sort-icon {
      margin-right: 8px;
    }
    ul {
      li {
        p {
          text-align: left;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    display: none;
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    padding: 0px;
    height: 100vh;
    overflow: scroll;
    background-color: #131c217a;
    z-index: 999;
    transition: left 300ms ease-in-out;
    &.active {
      display: block;
      transition: left 300ms ease-in-out;
      .listing-inner {
        position: absolute;
        left: -768px;
        top: 24px;
        width: 100%;
        transition: left 300ms ease-in-out;
        background-color: $white;
        height: calc(100% - 24px);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        &.active {
          left: 0;
          transition: left 300ms ease-in-out;
        }
        .sorter-popup {
          display: flex;
        }
        .dropdown {
          display: block;
          padding: 0;
          > :not(:last-child) {
            display: none;
          }
          ul {
            display: block;
            position: relative;
            max-height: initial;
            width: 100%;
            box-shadow: none;
            border-radius: 0;
            top: inherit;
            li {
              padding: 4px 16px;
              &:first-of-type {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
