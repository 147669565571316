<template>
    <b-button
        :variant="variant"
        :disabled="disabled"
        :class="classes"
        :aria-label="$t('page.product.addToWishlistButton')"
        @click="wishlistAction()"
    >
        <heart-icon />{{ label }}
    </b-button>
</template>

<script>
    import HeartIcon from 'vue-feather-icons/icons/HeartIcon';
    import { mapActions, mapState } from 'vuex';

    import UserAgentMixin from '@/mixins/userAgentMixin';
    import { generateToastHtml } from '@/services/generateToastHtml';

    export default {
        mixins: [UserAgentMixin],
        name: 'AddToWishlist',
        components: {
            HeartIcon,
        },
        props: {
            location: {
                type: String,
                default: 'detail',
            },
            product: {
                type: Object,
                default() {
                    return {};
                },
            },
            screenWidth: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                variant: 'primary',
                classes: 'stroke lg',
                breakpoint: 'md',
                exist: false,
                disabled: false,
            };
        },
        computed: {
            ...mapState('wishlist', ['products', 'inputErrors']),
            label() {
                if (this.location == 'listing' || this.screenWidth < 576) {
                    return '';
                }

                return this.exist
                    ? this.$t('page.product.alreadyAddedToWishlist')
                    : this.$t('page.product.addToWishlistButton');
            },
        },
        methods: {
            ...mapActions('wishlist', ['addItem', 'removeItem']),
            async wishlistAction() {
                this.disabled = true;
                if (
                    !Object.prototype.hasOwnProperty.call(this.products, this.product.code)
                ) {
                    await this.addItem(this.product);
                    if (Object.keys(this.inputErrors).length == 0) {
                        let message = this.$t('notification.productAddedToWishlistText');
                        let type = 'success';
                        let position = 'bottom-right';
                        if (!this.isMobileDevice) {
                            message = generateToastHtml(
                                'WISHLIST',
                                this.product.thumbnail,
                                this.product.name,
                                this.product.current_price
                            );
                            type = 'product';
                            position = 'top-right';
                        }
                        this.$toast.open({
                            message: message,
                            duration: 4000,
                            position: position,
                            type: type,
                        });
                    }

                } else {
                    this.exist = false;
                    this.removeItem(this.product.id);
                }
                this.disabled = false;
            },
            initialize() {
                this.breakpoint = this.$screen.breakpoint;
                let exist = '';
                this.variant = 'primary';
                this.classes = 'stroke lg';

                let responsive = '';
                if (this.$screen.breakpoint == 'md') {
                    responsive = ' responsive';
                }
                if (
                    Object.prototype.hasOwnProperty.call(this.products, this.product.code)
                ) {
                    exist = ' exist';
                    this.exist = true;
                }
                if (this.location == 'listing') {
                    this.classes = 'link icon sm' + exist;
                    this.variant = 'icon';
                } else {
                    this.classes = this.classes + exist;
                    if (this.screenWidth < 576) {
                        this.classes = 'stroke lg icon w-48';
                    }
                }

                this.classes = 'add-to-favorit ' + this.classes + responsive;
            },
        },
        mounted() {
            this.initialize();
        },
        watch: {
            products: {
                handler() {
                    this.initialize();
                },
                deep: true,
            },
            screenWidth() {
                this.initialize();
            },
        },
    };
</script>
<style lang="scss" scoped>
.btn {
  &:hover {
    :not(.link) {
      svg {
        stroke: $primary;
        fill: $primary;
      }
    }
    &.exist {
      svg {
        stroke: $red;
        fill: none;
      }
    }
  }
  &.responsive {
    &.icon {
      &.stroke {
        min-width: 48px;
      }
    }
    svg {
      stroke: $primary;
    }
    &.exist {
      svg {
        stroke: $primary;
        fill: $primary;
      }
    }
  }
  &.link {
    @include media-breakpoint-up(md) {
      &:hover {
        svg {
          stroke: $red;
        }
      }
      &.exist {
        svg {
          stroke: $red;
          fill: $red;
        }
      }
    }
  }
  &:focus-visible,
  &:focus,
  &:visited,
  &:active {
    border: none;
    outline: none;
    box-shadow: none;
  }
}
</style>
