import { i18n } from 'vue-lang-router';

import { splitedPrice } from '@/services/getSplitedPrice';

export const generateToastHtml = function (
  type,
  thumbnail,
  name,
  currentPrice
) {
  let lang = i18n.locale;
  let heading = type == 'CART' ? i18n.messages[lang].notification.productAddedToCartText : i18n.messages[lang].notification.productAddedToWishlistText;
  let image =
    type == 'WISHLIST'
      ? '<svg class="wishlist" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>'
      : '<svg class="cart" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>';
  let html = '<heart-icon />';
  html += '<div class="heading d-flex">';
  html += image;
  html += '<p class="sm-sbold title">' + heading + '</p>';
  html += '</div>';
  html += '<div class="data d-flex">';
  html += '<div class="left">';
  html += ' <img alt="' + name + '" src="' + thumbnail + '">';
  html += '</div>';
  html += ' <div class="right">';
  html += '<p class="sm-sbold title">' + name + '</p>';
  html += '<p class="price">' + splitedPrice(currentPrice) + '</p>';
  html += '</div>';
  html += '</div>';

  return html;
};
