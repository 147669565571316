<template>
    <div
        v-click-outside="closeFilter"
        class="side-box"
        :class="{
            closed: !filterIsActive,
            'quick-filter': location == 'content',
            mobile: isMobileFilter,
        }"
    >
        <div class="filter-container">
            <div
                v-if="location == 'content'"
                class="filter-label"
            >
                <p class="sm">
                    {{ label }}
                </p>
            </div>
            <div
                class="filter-header"
                :class="{ disabled: disabled }"
            >
                <div
                    v-if="location == 'content'"
                    class="close-filter"
                    @click="resetFilter()"
                >
                    <x-icon />
                </div>
                <span
                    v-if="!isMobileFilter"
                    class="d-flex"
                    @click="filterIsActive = !filterIsActive"
                >
                    <p :class="location == 'sidebar' ? 'sbold' : 'sm-sbold'">
                        {{ activeFilterValue }}
                    </p>
                    <chevron-up-icon />
                </span>
                <span v-else>
                    <p class="sidebar sm-sbold">{{ activeFilterValue }}</p>
                </span>
            </div>
        </div>
        <div
            v-if="!isMobileFilter"
            class="filter-content price-filter"
        >
            <div class="inner-filter">
                <ul>
                    <li
                        v-for="(filter, index) in filters.elements"
                        :key="`price-${index}`"
                    >
                        <label>
                            <div
                                v-if="index != Object.keys(filters.elements).length - 1"
                                class="text"
                            >
                                {{ filter.min }} - {{ filter.max }}
                            </div>
                            <div
                                v-else
                                class="text"
                            >{{ filterMinLabel(filter.min) }}</div>
                            <div
                                v-if="location == 'sidebar'"
                                class="quantity"
                            >
                                ({{ filter.count }})
                            </div>
                            <div class="custom-checkbox">
                                <input
                                    :id="location + '-' + `price-${index}`"
                                    type="checkbox"
                                    :name="filterKey"
                                    :value="getValue(filter)"
                                    :checked="isChecked(filter)"
                                    :disabled="filter.count == 0"
                                    @change="updateFilter($event)"
                                >
                                <div class="checkmark">
                                    <check-icon />
                                </div>
                            </div>
                        </label>
                    </li>
                </ul>
                <hr v-if="location == 'sidebar'">
                <div
                    v-if="location == 'sidebar'"
                    class="interval"
                >
                    <label>
                        <div class="custom-checkbox">
                            <input
                                type="checkbox"
                                name="interval"
                                :checked="intervalIsChecked"
                                @change="changeInterval($event)"
                            >
                            <div class="checkmark">
                                <check-icon />
                            </div>
                        </div>
                        <div class="text">
                            {{ $t('page.category.filters.priceInterval') }}
                        </div>
                    </label>
                </div>
                <div
                    v-if="location == 'sidebar'"
                    class="range-slider"
                >
                    <vue-slider
                        v-if="min != max"
                        :key="`slider-${sliderKey}`"
                        v-model="rangeValue"
                        aria-label="Selector de preț"
                        :drag-on-click="true"
                        :min="min"
                        :max="max"
                        :lazy="true"
                        @drag-end="dragSlider()"
                    />
                </div>
                <div
                    v-if="location == 'sidebar'"
                    class="range-values"
                >
                    <input
                        id="min-value"
                        type="number"
                        name="min-value"
                        aria-label="Valoarea minimă"
                        :value="rangeValue[0]"
                        @keyup.enter="updateCustomPrices($event)"
                        @keyup="updateCustomPrices($event)"
                    >
                    <div class="separator" />
                    <input
                        id="max-value"
                        type="number"
                        name="max-value"
                        :value="rangeValue[1]"
                        aria-label="Valoarea maximă"
                        @keyup.enter="updateCustomPrices($event)"
                        @keyup="updateCustomPrices($event)"
                    >
                    <b-button
                        :disabled="!filterByCustomPricesEnabled"
                        variant="text"
                        class="stroke icon sm"
                        :class="{ primary: filterByCustomPricesEnabled }"
                        aria-label="Filtrează după preț"
                        @click="filterByCustomPrices()"
                    >
                        <chevron-right-icon />
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'vue-slider-component/theme/default.css';

    import ClickOutside from 'vue-click-outside';
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import ChevronRightIcon from 'vue-feather-icons/icons/ChevronRightIcon';
    import ChevronUpIcon from 'vue-feather-icons/icons/ChevronUpIcon';
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { i18n } from 'vue-lang-router';
    import VueSlider from 'vue-slider-component';
    import { mapActions, mapState } from 'vuex';
    export default {
        name: 'PriceFilter',
        components: {
            CheckIcon,
            ChevronUpIcon,
            ChevronRightIcon,
            XIcon,
            VueSlider,
        },
        props: {
            location: {
                type: String,
                default: 'sidebar',
            },
            filters: {
                type: Object,
                default() {
                    return { elements: [], min_value: 0, max_value: 0 };
                },
            },
            label: {
                type: String,
                required: true,
            },
            filterKey: {
                type: String,
                required: true,
            },
            activeFilter: {
                type: [Object, Array],
                default() {
                    return {};
                },
            },
            isSearch: {
                type: Boolean,
                default: false,
            },
            isMobileFilter: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                min: 0,
                max: 0,
                rangeValue: [0, 9999],
                filterIsActive: true,
                sliderKey: 0,
                query: {
                    type: 'filter',
                    key: 'price',
                    value: { min: 0, max: 0 },
                },
                intervalIsChecked: false,
                filterByCustomPricesEnabled: false,
                firstLoad: true,
            };
        },
        computed: {
            ...mapState('category', [
                'mobileFilterAccordions',
                'products',
                'filtersCount',
            ]),
            activeFilterValue() {
                if (this.location == 'sidebar') {
                    return this.label;
                }
                if (
                    Object.prototype.hasOwnProperty.call(this.query.value, 'min') &&
                    Object.prototype.hasOwnProperty.call(this.query.value, 'max')
                ) {
                    let lastItem = this.filters.elements[this.filters.elements.length - 1];
                    if (
                        lastItem.min == this.query.value.min &&
                        lastItem.max == this.query.value.max
                    ) {
                        return this.filterMinLabel(this.query.value.min);
                    }
                    return this.query.value.min + '-' + this.query.value.max;
                }
                return '';
            },
            disabled() {
                return this.products.elements.length == 0 && this.filtersCount > 0;
            },
        },
        methods: {
            ...mapActions('category', ['getProducts']),
            getValue(filter) {
                return filter.min + '-' + filter.max;
            },
            isChecked(filter) {
                return (
                    filter.min + '-' + filter.max ==
                    this.query.value.min + '-' + this.query.value.max && filter.count > 0
                );
            },
            checkIfPredefinedInterval(filters) {
                let isPredefined = false;
                if (
                    Object.prototype.hasOwnProperty.call(this.query.value, 'min') &&
                    Object.prototype.hasOwnProperty.call(this.query.value, 'max')
                ) {
                    filters.forEach((filter) => {
                        if (
                            filter.min + '-' + filter.max ==
                            this.query.value.min + '-' + this.query.value.max &&
                            filter.count > 0
                        ) {
                            isPredefined = true;
                        }
                    });
                } else {
                    isPredefined = true;
                }
                return isPredefined;
            },
            closeFilter() {
                if (this.location === 'content') {
                    this.filterIsActive = false;
                }
            },
            resetFilter() {
                let resetQuery = {
                    type: 'filter',
                    key: 'price',
                    value: '',
                };
                this.getProducts({ query: resetQuery, isSearch: this.isSearch });
            },
            setRangeValues(min, max) {
                this.rangeValue[0] = parseInt(min);
                this.rangeValue[1] = parseInt(max);
            },
            updateFilter(event) {
                if (event.target.checked) {
                    let prices = event.target.value.split('-');
                    this.query.value.min = parseInt(prices[0]);
                    this.query.value.max = parseInt(prices[1]);
                    this.intervalIsChecked = false;
                } else {
                    this.query.value = '';
                }
                this.getProducts({ query: this.query, isSearch: this.isSearch });
            },
            dragSlider() {
                this.intervalIsChecked = true;
                this.query.value.min = this.rangeValue[0];
                this.query.value.max = this.rangeValue[1];
                this.getProducts({ query: this.query, isSearch: this.isSearch });
            },
            initializeFilter() {
                this.query.value = this.activeFilter;
                if (this.location === 'sidebar') {
                    let filters = this.filters;
                    let filterIntervals = this.filters.elements;
                    if (this.checkIfPredefinedInterval(filterIntervals)) {
                        this.intervalIsChecked = false;
                        this.setRangeValues(filters.min_value, filters.max_value);
                        if(this.firstLoad){
                            this.min = filters.min_value;
                            this.max = filters.max_value;
                        }
                    } else {
                        this.setRangeValues(this.activeFilter.min, this.activeFilter.max);
                        if(this.firstLoad){
                            this.min = this.activeFilter.min;
                            this.max = this.activeFilter.max;
                        }
                        this.intervalIsChecked = true;
                    }
                }
                this.firstLoad = false;
            },
            changeInterval($event) {
                let filters = this.filters;
                let min = 0;
                let max = 0;
                if (!$event.target.checked) {
                    this.intervalIsChecked = false;
                    min = filters.min_value;
                    max = filters.max_value;
                    this.setRangeValues(min, max);
                    this.query.value = '';
                    this.getProducts({ query: this.query, isSearch: this.isSearch });
                } else {
                    this.intervalIsChecked = true;
                    this.query.value.min = filters.min_value;
                    this.query.value.max = filters.max_value;
                    this.getProducts({ query: this.query, isSearch: this.isSearch });
                }
            },
            filterByCustomPrices() {
                let filters = this.filters;
                let min = this.rangeValue[0];
                let max = this.rangeValue[1];
                if (
                    min > max ||
                    min < filters.min_value ||
                    max > filters.max_value
                ) {
                    min = filters.min_value;
                    max = filters.max_value;
                }

                this.setRangeValues(min, max);
                this.query.value.min = min;
                this.query.value.max = max;
                this.getProducts({ query: this.query, isSearch: this.isSearch });
            },
            updateCustomPrices($event) {
                let min = 0;
                let max = 0;
                if (/^[1-9]\d*$|^$/.test($event.target.value) && $event.target.value) {
                    if ($event.target.name == 'max-value') {
                        min = this.rangeValue[0];
                        max = $event.target.value;
                    } else {
                        min = $event.target.value;
                        max = this.rangeValue[1];
                    }
                    this.setRangeValues(min, max);
                    this.filterByCustomPricesEnabled = true;

                    if ($event.keyCode && $event.keyCode == 13) {
                        this.filterByCustomPrices();
                    }
                }
            },
            filterMinLabel(value) {
                if (i18n.locale === 'ro') {
                    return this.$t('page.category.filters.priceMinimLabel') + ' ' + value;
                }
                return value + ' ' + this.$t('page.category.filters.priceMinimLabel');
            },
        },
        mounted() {
            if (this.location === 'content') {
                this.filterIsActive = false;
            }
            this.initializeFilter();
        },
        watch: {
            filters(filter) {
                this.min = filter.min_value;
                this.max = filter.max_value;
                this.sliderKey++;
            },
            activeFilter() {
                this.initializeFilter();
            },
            mobileFilterAccordions: {
                handler(value) {
                    if (!value) {
                        this.filterIsActive = true;
                    }
                },
            },
        },
        directives: {
            ClickOutside,
        },
    };
</script>
<style scoped lang="scss">
.side-box {
  &.closed {
    .filter-content {
      &.price-filter {
        max-height: 0px;
        transition: max-height 300ms ease-out;
      }
    }
  }
  .filter-content {
    &.price-filter {
      max-height: 290px;
    }
    .inner-filter {
      p {
        color: $text;
        margin: 12px 4px 0px 4px;
      }
      hr {
        margin: 12px 4px;
      }
      .interval {
        padding: 0px 4px;
        margin: 4px 0px 10px;
      }
      .range-slider {
        max-height: inherit;
        padding: 0px 12px;
        max-width: 300px;
        @include media-breakpoint-down(md) {
          max-width: 250px;
        }
        > div {
          z-index: 0;
        }
      }
      .range-values {
        max-height: inherit;
        display: flex;
        justify-content: space-between;
        vertical-align: middle;
        align-items: center;
        margin-top: 10px;
        padding: 2px 4px;
        max-width: 300px;
        @include media-breakpoint-down(md) {
          max-width: 250px;
        }
        input[type='number'] {
          -moz-appearance: textfield;
          border: 1px solid $gray-light;
          height: 32px;
          width: 80px;
          text-align: center;
          padding: 0px 8px;
          color: $text;
          font-size: 14px;
          @include media-breakpoint-between(xl, xxl) {
            width: 66px;
          }
          @include media-breakpoint-between(lg, xl) {
            width: 52px;
          }
        }
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
        }
        .btn {
          width: 32px;
          margin-top: 0;
          &.primary {
            background-color: $primary;
            svg {
              stroke: $white;
            }
          }
        }
        .separator {
          width: 32px;
          height: 1px;
          background-color: $gray-light;
          @include media-breakpoint-between(lg, xxl) {
            width: 16px;
          }
          @include media-breakpoint-down(md) {
            width: 3px;
          }
        }
      }
    }
  }
}
</style>
