<template>
    <div class="listing-header">
        <p>
            {{ $t('page.category.result') }}
        </p>
        <h6>{{ resultText }}</h6>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'ListingHeader',
        props: {
            isSearch: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapState('category', ['category', 'searchKey']),
            resultText() {
                return this.isSearch ? this.searchKey : this.category.name;
            },
        },
    };
</script>
<style scoped lang="scss">
.listing-header {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid $gray-light;
  @include media-breakpoint-down(md) {
    margin-bottom: 12px;
  }
  p {
    line-height: 120%;
  }
  h6 {
    margin-left: 10px;
    font-size: 18px;
  }
}
</style>
