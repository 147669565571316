<template>
    <div
        v-click-outside="closeFilter"
        class="side-box"
        :class="{
            closed: !filterIsActive,
            'quick-filter': location == 'content',
            mobile: isMobileFilter,
        }"
    >
        <div class="filter-container">
            <div
                v-if="location == 'content'"
                class="filter-label"
            >
                <p class="sm">
                    {{ label }}
                </p>
            </div>
            <div
                class="filter-header"
                :class="{ disabled: disabled }"
            >
                <div
                    v-if="location == 'content'"
                    class="close-filter"
                    @click="resetFilter()"
                >
                    <x-icon />
                </div>
                <span
                    v-if="!isMobileFilter"
                    class="d-flex"
                    @click="filterIsActive = !filterIsActive"
                >
                    <p :class="location == 'sidebar' ? 'sbold' : 'sm-sbold'">
                        {{ activeFilterValue }}
                    </p>
                    <chevron-up-icon />
                </span>
                <span v-else>
                    <p class="sidebar sm-sbold">{{ activeFilterValue }}</p>
                </span>
            </div>
        </div>

        <div
            v-if="!isMobileFilter"
            class="filter-content"
        >
            <div class="inner-filter">
                <ul class="scrollable">
                    <li
                        v-for="filter in filters"
                        :key="`filter-${filters.indexOf(filter)}`"
                    >
                        <label>
                            <div class="text">{{ filter.name }}</div>
                            <div
                                v-if="location == 'sidebar'"
                                class="quantity"
                            >
                                ({{ filter.count }})
                            </div>
                            <div class="custom-checkbox">
                                <input
                                    :id="location + '-' + filterKey + '-' + filter.key"
                                    :key="location + '-' + filterKey + '-' + filter.key"
                                    type="checkbox"
                                    :name="filter.key"
                                    :value="filter.key"
                                    :checked="filter.key == query.value && filter.count > 0"
                                    :disabled="filter.count == 0"
                                    @change="updateFilter($event)"
                                >
                                <div class="checkmark">
                                    <check-icon />
                                </div>
                            </div>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import ChevronUpIcon from 'vue-feather-icons/icons/ChevronUpIcon';
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { mapActions, mapState } from 'vuex';
    export default {
        name: 'TypeFilter',
        components: {
            CheckIcon,
            ChevronUpIcon,
            XIcon,
        },
        props: {
            location: {
                type: String,
                default: 'sidebar',
            },
            filters: {
                type: Array,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            filterKey: {
                type: String,
                required: true,
            },
            activeFilter: {
                type: String,
                default: '',
            },
            isSearch: {
                type: Boolean,
                default: false,
            },
            isMobileFilter: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                filterIsActive: true,
                query: {
                    type: 'filter',
                    key: 'type',
                    value: '',
                },
            };
        },
        computed: {
            ...mapState('category', [
                'mobileFilterAccordions',
                'products',
                'filtersCount',
            ]),
            activeFilterValue() {
                let label = this.label;
                if (this.location == 'content') {
                    this.filters.forEach((element) => {
                        if (element.key == this.activeFilter) {
                            label = element.name;
                        }
                    });
                }

                return label;
            },
            disabled() {
                return this.products.elements.length == 0 && this.filtersCount > 0;
            },
        },
        methods: {
            ...mapActions('category', ['getProducts']),
            resetFilter() {
                let resetQuery = {
                    type: 'filter',
                    key: 'type',
                    value: '',
                };
                this.getProducts({ query: resetQuery, isSearch: this.isSearch });
            },
            closeFilter() {
                if (this.location === 'content') {
                    this.filterIsActive = false;
                }
            },
            updateFilter(event) {
                if (event.target.checked) {
                    this.query.value = event.target.value;
                } else {
                    this.query.value = '';
                }
                this.getProducts({ query: this.query, isSearch: this.isSearch });
            },
            initiateFilter() {
                this.query.value = this.activeFilter;
            },
        },
        mounted() {
            if (this.location === 'content') {
                this.filterIsActive = false;
            }
            this.initiateFilter();
        },
        watch: {
            activeFilter() {
                this.initiateFilter();
            },
            mobileFilterAccordions: {
                handler(value) {
                    if (!value) {
                        this.filterIsActive = true;
                    }
                },
            },
        },
        directives: {
            ClickOutside,
        },
    };
</script>
