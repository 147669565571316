<template>
    <div
        class="listing-filter"
        :class="{ 'mobile': isMobileFilter }"
    >
        <type-filter
            v-if="isActive(filters.type.filter_key)"
            key="`listing-type-filter"
            :active-filter="getActiveFilters(filters.type.filter_key)"
            :filters="filters.type.elements"
            :label="$t('page.category.filters.type')"
            location="content"
            class="f-w1"
            :filter-key="filters.type.filter_key"
            :is-search="isSearch"
            :is-mobile-filter="isMobileFilter"
        />
        <price-filter
            v-if="isActive(filters.price.filter_key)"
            key="`listing-price-filter"
            :active-filter="getActiveFilters(filters.price.filter_key)"
            :filters="filters.price"
            :label="$t('page.category.filters.price')"
            location="content"
            class="f-w1"
            :filter-key="filters.price.filter_key"
            :is-search="isSearch"
            :is-mobile-filter="isMobileFilter"
        />
        <rating-filter
            v-if="isActive(filters.rating.filter_key)"
            key="`listing-rating-filter"
            location="content"
            :active-filter="getActiveFilters(filters.rating.filter_key)"
            :filters="filters.rating.elements"
            :label="$t('page.category.filters.rating')"
            class="f-w1"
            :filter-key="filters.rating.filter_key"
            :is-search="isSearch"
            :is-mobile-filter="isMobileFilter"
        />
        <standard-filter
            v-if="isActive(filters.manufacturers.filter_key)"
            key="`listing-manufacturers-filter"
            :filter-key="filters.manufacturers.filter_key"
            :filters="filters.manufacturers.elements"
            :active-filter="getActiveFilters(filters.manufacturers.filter_key)"
            location="content"
            :label="$t('page.category.filters.manufacturer')"
            class="f-w1"
            :is-search="isSearch"
            :is-mobile-filter="isMobileFilter"
        />
        <standard-filter
            v-for="(filter, key) in activeFilters.attributes"
            :key="`listing-${key}`"
            :active-filter="filter"
            :filters="getFilters(key)"
            :label="getFilterLabel(key)"
            location="content"
            class="f-w2"
            filter-key="attributes"
            :attribute-category="key"
            :is-search="isSearch"
            :is-mobile-filter="isMobileFilter"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import PriceFilter from '@/pages/category/PriceFilter';
    import RatingFilter from '@/pages/category/RatingFilter';
    import StandardFilter from '@/pages/category/StandardFilter';

    import TypeFilter from './TypeFilter';
    export default {
        name: 'ListingFilter',
        components: {
            PriceFilter,
            RatingFilter,
            StandardFilter,
            TypeFilter,
        },
        props: {
            isSearch: {
                type: Boolean,
                default: false,
            },
            isMobileFilter: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapState('category', ['filters', 'activeFilters']),
        },
        methods: {
            getActiveFilters(filterKey) {
                return this.activeFilters[filterKey];
            },
            getFilterLabel(filterKey) {
                let label = '';
                this.filters.attributes.elements.forEach((element) => {
                    if (element.filter_key == filterKey) {
                        label = element.name;
                    }
                });
                return label;
            },

            getFilters(filterKey) {
                let filters = [];
                this.filters.attributes.elements.forEach((element) => {
                    if (element.filter_key == filterKey) {
                        filters = element.attributes;
                    }
                });
                return filters;
            },

            isActive(filterKey) {
                if (filterKey != '') {
                    if (this.activeFilters[filterKey] instanceof Object) {
                        return Object.keys(this.activeFilters[filterKey]).length;
                    }
                    if (this.activeFilters[filterKey] == '') {
                        return false;
                    }
                } else {
                    return false;
                }
                return true;
            },
        },
    };
</script>
<style scoped lang="scss">
.listing-filter {
  display: flex;
  padding: 16px 0px;
  gap: 16px;
  flex-wrap: wrap;
  &.mobile {
    padding: 6px 16px;
    flex-wrap: nowrap;
  }
}
</style>
