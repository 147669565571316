var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-box"},[_c('div',{staticClass:"parent-category"},[(_vm.categories.icon)?_c('img',{attrs:{"src":_vm.categories.icon,"alt":_vm.categoryLabel}}):_vm._e(),_c('p',{staticClass:"sbold"},[_vm._v(" "+_vm._s(_vm.categoryLabel)+" ")])]),_c('div',{staticClass:"filter-content"},[_c('div',{staticClass:"inner-filter"},[_c('ul',{staticClass:"scrollable"},_vm._l((_vm.categories.elements),function(category){return _c('li',{key:`product-${_vm.categories.elements.indexOf(category)}`,class:{
                        'has-subcategories':
                            category.elements && category.elements.length > 0,
                        active:
                            category.name === _vm.current.name ||
                            category.slug === _vm.category_slug,
                    }},[_c('a',{staticClass:"search-category",class:{ 'search-category': _vm.isSearch },on:{"click":function($event){return _vm.updateFilter(category)}}},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(category.name)+" ")]),_c('div',{staticClass:"quantity"},[_vm._v("("+_vm._s(category.count)+")")])]),_c('ul',_vm._l((category.elements),function(subcategory){return _c('li',{key:`product-${category.elements.indexOf(subcategory)}`},[_c('router-link',{attrs:{"to":{ path: '/categorie/' + subcategory.path }}},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(subcategory.name)+" ")]),_c('div',{staticClass:"quantity"},[_vm._v(" ("+_vm._s(subcategory.count)+") ")])])],1)}),0)])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }