<template>
    <div class="sidebar">
        <div
            v-if="isResponsive"
            class="filter-popup"
        >
            <p class="sbold">
                {{ $t('page.category.filters.mobileHeading') }}
            </p>
            <b-button
                variant="link"
                class="close icon"
                @click="closeFilter()"
            >
                <x-icon />
            </b-button>
        </div>
        <div
            class="sidebar-selected-filters"
            :class="{ active: filtersCount > 0 }"
        >
            <listing-filter
                v-if="showSelectedFilters"
                :is-search="isSearch"
                :is-mobile-filter="true"
            />
        </div>
        <div
            class="sidebar-filters"
            :style="{ height: computedHeight }"
            :class="{ 'has-selected': filtersCount > 0 }"
        >
            <category-filter
                v-if="categories.elements.length > 0"
                :categories="categories"
                :current="category"
                :is-search="isSearch"
            />
            <type-filter
                v-if="filters.type.elements.length"
                key="`sidebar-type-filter"
                :active-filter="getActiveFilters(filters.type.filter_key)"
                :filters="filters.type.elements"
                :filter-key="filters.type.filter_key"
                :label="$t('page.category.filters.type')"
                :is-search="isSearch"
            />
            <price-filter
                v-if="showPriceFilter"
                key="`sidebar-price-filter"
                :active-filter="getActiveFilters(filters.price.filter_key)"
                :filters="filters.price"
                :filter-key="filters.price.filter_key"
                :label="$t('page.category.filters.price')"
                :is-search="isSearch"
            />
            <rating-filter
                key="`sidebar-rating-filter"
                :filters="filters.rating.elements"
                :filter-key="filters.rating.filter_key"
                :active-filter="getActiveFilters(filters.rating.filter_key)"
                :label="$t('page.category.filters.rating')"
                :is-search="isSearch"
            />
            <standard-filter
                v-if="filters.manufacturers.elements.length"
                key="`sidebar-manufacturers-filter"
                :filter-key="filters.manufacturers.filter_key"
                :filters="filters.manufacturers.elements"
                :active-filter="getActiveFilters(filters.manufacturers.filter_key)"
                :label="$t('page.category.filters.manufacturer')"
                :is-search="isSearch"
            />
            <div v-if="showStandardFilters">
                <standard-filter
                    v-for="filter in filterAttributes"
                    :key="`sidebar-${filter.filter_key}`"
                    :active-filter="getActiveAttributeFilters(filter.filter_key)"
                    :filters="filter.attributes"
                    :label="filter.name"
                    filter-key="attributes"
                    :attribute-category="filter.filter_key"
                    :is-search="isSearch"
                />
            </div>
        </div>
        <div
            v-if="isResponsive"
            class="filter-actions"
        >
            <b-button
                variant="text"
                class="stroke sm"
                @click="resetFilter()"
            >
                {{ $t('page.category.filters.resetAllButton') }}
            </b-button>
            <b-button
                variant="primary"
                class="sm reset"
                @click="closeFilter()"
            >
                {{ $t('page.category.filters.seeAllButton') }}
            </b-button>
        </div>
    </div>
</template>

<script>
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { mapActions, mapMutations, mapState } from 'vuex';

    import CategoryFilter from '@/pages/category/CategoryFilter';
    import PriceFilter from '@/pages/category/PriceFilter';
    import RatingFilter from '@/pages/category/RatingFilter';
    import StandardFilter from '@/pages/category/StandardFilter';

    import ListingFilter from './ListingFilter';
    import TypeFilter from './TypeFilter';
    export default {
        name: 'Sidebar',
        components: {
            CategoryFilter,
            PriceFilter,
            RatingFilter,
            StandardFilter,
            TypeFilter,
            ListingFilter,
            XIcon,
        },
        props: {
            isSearch: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                computedHeight: 'auto',
                screenWidth: 0,
            };
        },
        computed: {
            ...mapState('category', [
                'loading',
                'filters',
                'activeFilters',
                'filtersCount',
                'categories',
                'category',
                'products',
            ]),
            ...mapState('general', ['scrollBasedVisibility']),
            showPriceFilter() {
                if (this.filters.price.elements.length > 0) {
                    if (this.filters.price.elements.length == 1) {
                        if (
                            this.filters.price.elements[0].min ==
                            this.filters.price.elements[0].max
                        ) {
                            return false;
                        }
                    }
                    return true;
                }
                return false;
            },
            filterAttributes() {
                if (this.filters.attributes) {
                    return this.filters.attributes.elements;
                }
                return [];
            },
            isResponsive() {
                return this.screenWidth < 768;
            },
            showSelectedFilters() {
                return (
                    this.isResponsive &&
                    (this.products.elements.length > 0 ||
                        (this.products.elements.length == 0 && this.filtersCount > 0))
                );
            },
            showStandardFilters() {
                return (
                    this.isResponsive ||
                    this.scrollBasedVisibility ||
                    (this.products.page.total <= 24 &&
                        !this.category.has_sub_categories)
                );
            },
        },
        methods: {
            ...mapActions('category', ['getProducts']),
            ...mapMutations('category', ['setMobileFiltersStatus', 'toggleAccordions']),
            getActiveFilters(filterKey) {
                return this.activeFilters[filterKey];
            },
            getActiveAttributeFilters(filterKey) {
                return this.activeFilters.attributes[filterKey];
            },
            closeFilter() {
                this.setMobileFiltersStatus(false);
                this.toggleAccordions(false);
            },
            resetFilter() {
                this.getProducts({
                    query: null,
                    isSearch: this.isSearch,
                    resetAll: true,
                });
                this.closeFilter();
            },
            updateSizes() {
                this.screenWidth = window.innerWidth;
                let viewportHeight = window.innerHeight;
                let calculatedHeight = viewportHeight - 20 - 49 - 49;
                if (this.filtersCount > 0) {
                    calculatedHeight = calculatedHeight - 72;
                }
                if (this.isResponsive) {
                    this.computedHeight = `${calculatedHeight}px`;
                }
            },
        },
        mounted() {
            window.addEventListener('resize', this.updateSizes);
            this.updateSizes();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.updateHeight);
        },
        watch: {
            filtersCount() {
                this.updateSizes();
            },
        },
    };
</script>
<style lang="scss">
@import 'src/_scss/components/filter.scss';
</style>
