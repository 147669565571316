<template>
    <b-col
        class="page-container"
        :class="{
            'col-12 col-md-8 col-lg-9': loading,
            'col-12': isSearch && categories.elements.length == 0,
        }"
    >
        <listing-header
            v-if="showHeader"
            :class="{ transparent: loading }"
            :is-search="isSearch"
        />
        <listing-header-loader
            v-if="showHeaderLoader"
            class="mb-4"
        />
        <listing-filter-actions
            v-if="showMobileFilterActions"
            @toggleSorter="toggleSorter"
        />
        <listing-filter
            v-if="showFilter"
            :class="{ transparent: loading }"
            :is-search="isSearch"
        />
        <listing-actions
            v-if="showActions"
            :class="{ transparent: loading }"
            :is-search="isSearch"
            :page="products.page"
            :discount-exist="products.data.discount_exist"
            :sorter-bar-active="sorterBarActive"
            :sorter-active="sorterActive"
            :not-exist-label="emptyText"
            @toggleSorter="toggleSorter"
        />
        <no-results
            v-if="showNoResults && !loading"
            search-key="searchKey"
        />
        <div
            v-if="existProducts"
            ref="listingBox"
            class="row g-4 products-list"
            :class="{ transparent: loading }"
        >
            <div
                v-for="product in products.elements"
                :key="`product-${products.elements.indexOf(product)}`"
                class="column"
            >
                <product-card
                    :search-key="searchKey"
                    :is-search="isSearch"
                    :product="product"
                    :category-has-sub-categories="category.has_sub_categories"
                />
            </div>
        </div>
        <div
            v-if="!existProducts && loading"
            class="row g-4 loader product-listing"
        >
            <div
                v-for="index in 12"
                :key="`product-loader-${index}`"
                class="column"
            >
                <product-card-loader />
            </div>
        </div>
        <listing-pagination
            v-if="productsCount > 0"
            :page="products.page"
            :loading="loading"
            :scrolling="scrolling"
            :is-search="isSearch"
        />
    </b-col>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';

    import ListingHeaderLoader from '@/components/loaders/category/ListingHeaderLoader';
    import ProductCardLoader from '@/components/loaders/category/ProductCardLoader';
    import ProductCard from '@/components/product/ProductCard';
    import UserAgentMixin from '@/mixins/userAgentMixin';
    import ListingFilterActions from '@/pages/category/ListingFilterActions';

    import NoResults from '../NoResults';
    import ListingActions from './ListingActions';
    import ListingFilter from './ListingFilter';
    import ListingHeader from './ListingHeader';
    import ListingPagination from './ListingPagination';

    export default {
        mixins: [UserAgentMixin],
        name: 'ProductListing',
        components: {
            ListingHeader,
            ListingFilter,
            ListingFilterActions,
            ProductCard,
            ListingPagination,
            ListingActions,
            ProductCardLoader,
            ListingHeaderLoader,
            NoResults,
        },
        props: {
            isSearch: {
                type: Boolean,
                default: false,
            },
            pageChanged: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                perPage: 24,
                sorterBarActive: false,
                sorterActive: false,
                screenWidth: window.innerWidth,
            };
        },
        computed: {
            ...mapState('category', [
                'category',
                'loading',
                'scrolling',
                'categories',
                'products',
                'filtersCount',
            ]),
            productsCount() {
                return this.products.elements.length;
            },
            showNoResults() {
                return this.isSearch && this.productsCount == 0;
            },
            showHeader() {
                return (
                    this.productsCount > 0 ||
                    (this.productsCount == 0 && !this.loading && !this.isSearch)
                );
            },
            showFilter() {
                return !this.isResponsive && this.showHeader;
            },
            showActions() {
                return (
                    this.productsCount > 0 ||
                    (this.productsCount == 0 && !this.loading && !this.isSearch)
                );
            },
            showMobileFilterActions() {
                return (
                    (this.productsCount > 0 ||
                        (this.productsCount == 0 && this.filtersCount > 0)) &&
                    this.isResponsive &&
                    !this.loading
                );
            },
            showHeaderLoader() {
                return this.productsCount == 0 && this.loading && !this.isResponsive;
            },
            isResponsive() {
                return this.screenWidth < 768;
            },
            searchKey() {
                return this.isSearch ? this.$route.query.key : '';
            },
            existProducts() {
                return this.productsCount > 0;
            },
            emptyText() {
                return this.filtersCount > 0
                    ? this.$t('page.category.noResultForFilters')
                    : this.$t('page.category.noResult');
            },
        },
        methods: {
            ...mapMutations('category', ['toggleAccordions']),
            toggleSorter() {
                if (!this.sorterBarActive) {
                    this.sorterBarActive = true;
                    document.body.classList.add('no-scrolling');
                    setTimeout(() => {
                        this.sorterActive = true;
                    }, 1);
                } else {
                    this.sorterActive = false;
                    setTimeout(() => {
                        this.sorterBarActive = false;
                        document.body.classList.remove('no-scrolling');
                    }, 301);
                }
            },
            screenSizeChanged() {
                this.screenWidth = window.innerWidth;
            },
        },
        mounted() {
            window.addEventListener('resize', this.screenSizeChanged);
        },
        unmounted() {
            window.removeEventListener('resize', this.screenSizeChanged);
        },
    };
</script>
<style scoped lang="scss">
.row {
  &.g-4 {
    min-height: 270px;
  }
  .column {
    width: calc(100% / 4);
    @include media-breakpoint-down(xl) {
      width: calc(100% / 3);
    }
    @include media-breakpoint-down(xs-sm) {
      width: calc(100% / 2);
    }
  }
}
</style>
