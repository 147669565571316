<template>
    <b-row>
        <b-col cols="12">
            <div class="no-results">
                <div class="texts">
                    <h4>
                        {{ $t('page.search.notFoundText') }} <span>"{{ searchKey }}"</span>
                    </h4>
                    <p>
                        {{ $t('page.search.notFoundText2') }}
                    </p>
                </div>
                <div
                    v-if="!loading"
                    class="categories"
                >
                    <h6>{{ $t('page.search.categoryHeading') }}</h6>
                    <div class="items d-flex mt-4">
                        <div
                            v-for="category in categories"
                            :key="`category-${categories.indexOf(category)}`"
                            class="item"
                        >
                            <router-link :to="{ path: '/categorie/' + category.path }">
                                <img
                                    :src="category.icon"
                                    :alt="category.name"
                                >
                                <span>{{ category.name }}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'NoResults',
        computed: {
            ...mapState('category', ['searchKey']),
            ...mapState('navigation', ['categories', 'loading']),
        },
    };
</script>
<style scoped lang="scss">
.no-results {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 500px;

  h4 {
    color: $text;
    margin-bottom: 16px;
    font-weight: 400;
    span {
      color: $text;
      font-weight: 700;
    }
  }
  .categories {
    h6 {
      color: $text;
      margin-top: 24px;
    }
    .items {
      flex-wrap: wrap;
      gap: 16px;
      .item {
        width: calc(20% - 14px);
        @include media-breakpoint-between(md, xl) {
          width: calc(25% - 12px);
        }
        @include media-breakpoint-between(xm, md) {
          width: calc(50% - 8px);
        }
        @include media-breakpoint-down(xm) {
          width: 100%;
        }
        a {
          height: 66px;
          border: 1px solid $gray-light;
          align-items: center;
          display: flex;
          padding: 16px;
          img {
            width: 32px;
            height: 32px;
          }
          span {
            margin-left: 8px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>
