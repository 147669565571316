<template>
    <b-button
        variant="primary"
        :disabled="isDisabled"
        :class="'add-to-cart ' + size + ' ' + type + ' ' + isIcon"
        @click="addProductToCart()"
    >
        <shopping-cart-icon />{{ buttonLabel }}
    </b-button>
</template>

<script>
    import ShoppingCartIcon from 'vue-feather-icons/icons/ShoppingCartIcon';
    import { mapActions, mapState } from 'vuex';

    import UserAgentMixin from '@/mixins/userAgentMixin';
    import { generateToastHtml } from '@/services/generateToastHtml';

    export default {
        mixins: [UserAgentMixin],
        name: 'AddToCart',
        components: {
            ShoppingCartIcon,
        },
        props: {
            label: {
                type: Boolean,
                default: true,
            },
            type: {
                type: String,
                default: 'fill',
            },
            isIcon: {
                type: String,
                default: '',
            },
            size: {
                type: String,
                default: 'sm',
            },
            stock: {
                type: Number,
                default: 1,
            },
            product: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        data(){
            return {
                disabled: false,
            };
        },
        computed: {
            ...mapState('cart', ['inputErrors']),
            buttonLabel() {
                let label = !this.label
                    ? ''
                    : this.stock != 0
                        ? this.$t('page.product.addToCartButton')
                        : this.$t('page.product.stock.unavailable');
                if (this.isIcon == 'icon') {
                    label = '';
                }
                return label;
            },
            isDisabled(){
                return this.stock == 0 || this.disabled;
            }
        },
        methods: {
            ...mapActions('cart', ['addItem']),
            async addProductToCart() {
                this.disabled = true;
                await this.addItem({ item: this.product, isVoucher: false });
                if (Object.keys(this.inputErrors).length == 0) {
                    let message = this.$t('notification.productAddedToCartText');
                    let type = 'success';
                    let position = 'bottom-right';
                    if (!this.isMobileDevice) {
                        let price = Object.prototype.hasOwnProperty.call(
                            this.product,
                            'current_price'
                        )
                            ? this.product.current_price
                            : this.product.value;
                        message = generateToastHtml(
                            'CART',
                            this.product.thumbnail,
                            this.product.name,
                            price
                        );
                        type = 'product';
                        position = 'top-right';
                    }

                    this.$toast.open({
                        message: message,
                        duration: 4000,
                        position: position,
                        type: type,
                    });
                }
                this.disabled = false;
            },
        },
    };
</script>
<style scoped lang="scss">
.btn {
  &:not(.icon) {
    width: 100%;
  }
}
</style>
