<template>
    <ContentLoader
        primary-color="#f6f7f8"
        secondary-color="#eef0f2"
        width="190"
        height="999"
        preserveAspectRatio="none"
        :speed="1"
    >
        <rect
            x="0"
            y="0"
            rx="4"
            ry="4"
            width="100%"
            height="190"
        />
        <rect
            x="0"
            y="202"
            rx="4"
            ry="4"
            width="100%"
            height="140"
        />
        <rect
            x="0"
            y="354"
            rx="4"
            ry="4"
            width="100%"
            height="180"
        />
        <rect
            x="0"
            y="546"
            rx="4"
            ry="4"
            width="100%"
            height="80"
        />
        <rect
            x="0"
            y="638"
            rx="4"
            ry="4"
            width="100%"
            height="260"
        />
    </ContentLoader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader';

    export default {
        name:'SidebarLoader',
        components: {
            ContentLoader,
        },
    };
</script>