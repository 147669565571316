export const splitedPrice = function (price) {
  let prices = price.toString().split('.');
  let grossPrice = prices.length > 1 ? prices[0] : prices;
  let decimalPrice = prices.length > 1 ? prices[1] : '00';
  if (decimalPrice.length == 1) {
    decimalPrice = decimalPrice + '0';
  }
  let html =
    '<span class="big">' + grossPrice + '<sup>' + decimalPrice + '</sup> Ron';
  return html;
};
